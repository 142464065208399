import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Company = ({ data, location } ) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
      <Layout location={location} title={siteTitle}>
            <Seo title="会社概要" />
            <div className="">

        <h1>会社概要</h1>
        <table>
          <thead>
            <tr>
              <th>会社名</th>
              <th>アイゼック株式会社</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>設立</td>
              <td>2004年10月</td>
            </tr>
            <tr>
              <td>資本金</td>
              <td>4,000,000円</td>
            </tr>
            <tr>
              <td>代表取締役社長</td>
              <td>中村　龍一</td>
            </tr>
            <tr>
              <td>所在地</td>
              <td>〒183-0045<br/>東京都府中市美好町2-9-2</td>
            </tr>
            <tr>
              <td>連絡先</td>
              <td>TEL:042-369-2041<br/>FAX:042-369-2042</td>
            </tr>
            <tr>
              <td>事業内容</td>
              <td>画像関連装置の開発販売</td>
            </tr>
            <tr>
              <td>取引銀行</td>
              <td>三井住友銀行<br/>きらぼし銀行<br/>三菱UFJ銀行<br/></td>
            </tr>
            <tr>
              <td>加入団体</td>
              <td>むさし府中商工会議所<br/>武蔵府中法人会</td>
            </tr>
          </tbody>
        </table>
        <h1>アクセス</h1>
        <h5>〒183-0045　東京都府中市美好町2-9-2</h5>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.1810816744223!2d139.46221811525862!3d35.67254298019635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018e48af89d108f%3A0x17651a3f87d29959!2z44CSMTgzLTAwNDUg5p2x5Lqs6YO95bqc5Lit5biC576O5aW955S677yS5LiB55uu77yZ4oiS77yS!5e0!3m2!1sja!2sjp!4v1615449105579!5m2!1sja!2sjp" width="100%" height={450} style={{border:0}} allowfullscreen="" loading="lazy" title="Map"></iframe>
        </div>
        </Layout>
    )
}

export default Company

export const companyQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`